import styles from "./App.module.css";

function App() {
  return (
    <main className={styles.main}>
      <div className={styles.description}>
        <h1>LeafLux</h1>
        <p>Coming soon</p>
      </div>
    </main>
  );
}

export default App;
